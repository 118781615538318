import { FC, useCallback, useEffect, useRef, useState } from 'react';

import LoadingSpinner from '../../components/loading-spinner/LoadingSpinner';
import { useAdminContext } from '../../contexts/admin-context';
import { handleImageAsFile } from '../../getto-client/firebase';
import {
  addSchedule,
  Schedule
} from '../../getto-client/collections/schedules';

const AdminSchedulesInput: FC = () => {
  const { allSchedules } = useAdminContext();
  const [newsError, setNewsError] = useState(false);
  const [imageUploading, setImageUploading] = useState(false);
  const grafikRef1 = useRef<HTMLInputElement>(null);
  const grafikRef2 = useRef<HTMLInputElement>(null);
  const homeRef = useRef<HTMLInputElement>(null);
  const [assetUrlInput, setAssetUrlInput] = useState<string>('');
  const [success, setSuccess] = useState(false);

  const getNameFromCheckBoxes = () => {
    if (homeRef.current?.checked) return 'HOME';
    if (grafikRef1.current?.checked) return 'MAIN';
    if (grafikRef2.current?.checked) return 'MAIN2';
    else return '';
  };

  const handleHomeRefChange = (e: any) => {
    if (e.target?.checked) {
      if (grafikRef1.current?.checked) grafikRef1.current.checked = false;
      if (grafikRef2.current?.checked) grafikRef2.current.checked = false;
    }
  };

  const handleGrafikRef1Change = (e: any) => {
    if (e.target?.checked) {
      if (homeRef.current?.checked) homeRef.current.checked = false;
      if (grafikRef2.current?.checked) grafikRef2.current.checked = false;
    }
  };

  const handleGrafikRef2Change = (e: any) => {
    if (e.target?.checked) {
      if (homeRef.current?.checked) homeRef.current.checked = false;
      if (grafikRef1.current?.checked) grafikRef1.current.checked = false;
    }
  };

  const handleImageAsUrl = useCallback(async (files: FileList | null) => {
    if (!files || !files.length) {
      setNewsError(true);
      return;
    } else {
      try {
        setImageUploading(true);
        const file = files.item(0);
        if (!file) return;
        const _url = await handleImageAsFile(file);
        setAssetUrlInput(_url);
      } catch (error) {
        setNewsError(true);
        console.error('error uploading image..');
      } finally {
        setImageUploading(false);
      }
    }
  }, []);

  const validateAndAddSchedule = (
    name?: string | null,
    assetUrlInput?: string,
    schedules?: Schedule[]
  ) => {
    if (!name || !name.length || !schedules || !assetUrlInput) {
      setNewsError(true);
      return;
    } else {
      const schedule = schedules?.find((cat: Schedule) => cat.name === name);
      addSchedule(schedule?.name, schedule?.ref, assetUrlInput).then(() =>
        setSuccess(true)
      );
    }
  };

  useEffect(() => {
    if (success) {
      if (newsError) setNewsError(false);
      const timeout1 = setTimeout(() => setSuccess(false), 3500);
      return () => {
        if (timeout1) clearTimeout(timeout1);
      };
    }
  }, [success, newsError]);

  return (
    <div data-error={newsError} className="inputs">
      <h4>Zmień Rozkład Jazdy </h4>
      <p>
        Zaznacz, jeśli to zdjęcie zmieniło się na Stronie GRAFIK I CENNIK Po
        Lewej
      </p>
      <input
        className="check"
        defaultChecked={true}
        placeholder="grafic-photo1"
        onChange={(e) => handleGrafikRef1Change(e)}
        ref={grafikRef1}
        type="checkbox"
      />
      <p>
        Zaznacz, jeśli to zdjęcie zmieniło się na Stronie GRAFIK I CENNIK Po
        Prawej
      </p>
      <input
        className="check"
        placeholder="grafic-photo2"
        onChange={(e) => handleGrafikRef2Change(e)}
        ref={grafikRef2}
        type="checkbox"
      />
      <p>Zaznacz, jeśli to zdjęcie zmieniło się na Stronie Głównej</p>
      <input
        className="check"
        placeholder="home"
        onChange={(e) => handleHomeRefChange(e)}
        ref={homeRef}
        type="checkbox"
      />
      <h5>Dodaj zdjęcie Rozkładu jazdy w formacie .png</h5>
      <input
        placeholder="Wybierz Zdjęcie "
        onChange={(e) => handleImageAsUrl(e.target.files)}
        accept="image/*"
        type="file"
      />
      {newsError && <h4>Cos poszło nie tak.. spróbuj ponownie</h4>}
      {success && <h4>Rozkład Jazdy został zmieniony pomyślnie.</h4>}
      {!imageUploading && (
        <button
          onClick={() => {
            validateAndAddSchedule(
              getNameFromCheckBoxes(),
              assetUrlInput,
              allSchedules
            );
          }}
        >
          Zmień
        </button>
      )}
      {imageUploading && <LoadingSpinner />}
    </div>
  );
};

export default AdminSchedulesInput;

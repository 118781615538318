import LoadingSpinner from '../../components/loading-spinner/LoadingSpinner';

import "./GettoSplashScreen.scss"

const GettoSplashScreen = () => {

  return (
    <section className="getto-splash-section">
      <div>
        <LoadingSpinner />
      </div>
    </section>
  );
};

export default GettoSplashScreen;

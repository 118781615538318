import React, { FC } from 'react';
import { ReactComponent as CloseIcon } from '../../assets/closeButton.svg';
import { Photo } from '../photo-modal/PhotoModal';
import './close-button.scss';

const CloseButton: FC<{
  closingFunction?: () => void;
}> = ({ closingFunction }) => {
  return (
    <button
      className="close-button-container"
      onClick={() => {
        if (closingFunction) closingFunction();
      }}
    >
      <CloseIcon />
    </button>
  );
};

export default CloseButton;

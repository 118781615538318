import { FC } from 'react';
import AdminScheduleInput from '../admin-inputs/ScheduleInput';


const SchedulesPanel: FC = () => {
  return (
    <div className="schedule-panel">
      <div>
        <AdminScheduleInput />
        <div className="data-table" style={{ maxWidth: '100%' }}>
        </div>
      </div>
    </div>
  );
};

export default SchedulesPanel;

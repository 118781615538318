import { BrowserRouter, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import GettoMainSection from '../components/getto-main-section/GettoMainSection';
import Info from '../pages/home/Home';
import Contact from '../pages/contact/Contact';
import GettoSchool from '../pages/getto-school/GettoSchool';
import Prices from '../pages/prices/Prices';
import News from '../pages/news/News';
import PageNotFound from '../pages/page-not-found/PageNotFound';
import Rules from '../pages/rules/Rules';
import ScrollToTop from './ScrollTop';
import AdminApp from '../admin/AdminApp';

const Router = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/admin" element={<AdminApp />} />
        <Route path="*" element={<GettoMainSection />}>
          <Route path="" element={<Info />} />
          <Route path="news" element={<News />} />
          <Route path="prices" element={<Prices />} />
          <Route path="school/*" element={<GettoSchool />} />
          <Route path="contact" element={<Contact />} />
          <Route path="rules" element={<Rules />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;

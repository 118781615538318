import { FC, useCallback, useEffect, useRef, useState } from 'react';

import LoadingSpinner from '../../components/loading-spinner/LoadingSpinner';
import { useAdminContext } from '../../contexts/admin-context';
import { handleImageAsFile } from '../../getto-client/firebase';
import {
  Instructor,
  addInstructor
} from '../../getto-client/collections/instructors';

const AdminInstructorsInput: FC = () => {
  const { allInstructors } = useAdminContext();
  const [newsError, setNewsError] = useState(false);
  const [imageUploading, setImageUploading] = useState(false);
  const skateRef = useRef<HTMLInputElement>(null);
  const rolkiRef = useRef<HTMLInputElement>(null);
  const rowerRef = useRef<HTMLInputElement>(null);
  const scooterRef = useRef<HTMLInputElement>(null);
  const groupRef = useRef<HTMLInputElement>(null);
  const nameRef = useRef<HTMLInputElement>(null);
  const telefonRef = useRef<HTMLInputElement>(null);
  const [assetUrlInput, setAssetUrlInput] = useState<string>('');
  const [success, setSuccess] = useState(false);

  const getNameFromCheckBoxes = () => {
    if (skateRef.current?.checked) return 'SKATE';
    if (rolkiRef.current?.checked) return 'ROLKI';
    if (rowerRef.current?.checked) return 'ROWER';
    if (scooterRef.current?.checked) return 'HULAJNOGA';
    if (groupRef.current?.checked) return 'GROUP';
    else return '';
  };

  const handleSkateRefChange = (e: any) => {
    if (e.target?.checked) {
      if (rolkiRef.current?.checked) rolkiRef.current.checked = false;
      if (rowerRef.current?.checked) rowerRef.current.checked = false;
      if (scooterRef.current?.checked) scooterRef.current.checked = false;
      if (groupRef.current?.checked) groupRef.current.checked = false;
    }
  };

  const handleRolkiRefChange = (e: any) => {
    if (e.target?.checked) {
      if (skateRef.current?.checked) skateRef.current.checked = false;
      if (rowerRef.current?.checked) rowerRef.current.checked = false;
      if (scooterRef.current?.checked) scooterRef.current.checked = false;
      if (groupRef.current?.checked) groupRef.current.checked = false;
    }
  };

  const handleRowerRefChange = (e: any) => {
    if (e.target?.checked) {
      if (rolkiRef.current?.checked) rolkiRef.current.checked = false;
      if (skateRef.current?.checked) skateRef.current.checked = false;
      if (scooterRef.current?.checked) scooterRef.current.checked = false;
      if (groupRef.current?.checked) groupRef.current.checked = false;
    }
  };

  const handleScooterRefChange = (e: any) => {
    if (e.target?.checked) {
      if (rolkiRef.current?.checked) rolkiRef.current.checked = false;
      if (rowerRef.current?.checked) rowerRef.current.checked = false;
      if (skateRef.current?.checked) skateRef.current.checked = false;
      if (groupRef.current?.checked) groupRef.current.checked = false;
    }
  };

  const handleGroupRefChange = (e: any) => {
    if (e.target?.checked) {
      if (rolkiRef.current?.checked) rolkiRef.current.checked = false;
      if (rowerRef.current?.checked) rowerRef.current.checked = false;
      if (scooterRef.current?.checked) scooterRef.current.checked = false;
      if (skateRef.current?.checked) skateRef.current.checked = false;
    }
  };

  const handleImageAsUrl = useCallback(async (files: FileList | null) => {
    if (!files || !files.length) {
      setNewsError(true);
      return;
    } else {
      try {
        setImageUploading(true);
        const file = files.item(0);
        if (!file) return;
        const _url = await handleImageAsFile(file);
        setAssetUrlInput(_url);
      } catch (error) {
        setNewsError(true);
        console.error('error uploading image..');
      } finally {
        setImageUploading(false);
      }
    }
  }, []);

  const validateAndAddInstructor = (
    name?: string | null,
    instructorName?: string,
    phone?: string,
    instructors?: Instructor[],
    assetUrlInput?: string | undefined
  ) => {
    if (
      !name ||
      !instructorName ||
      !instructorName.length ||
      !phone ||
      !phone.length ||
      !assetUrlInput ||
      !instructors ||
      !assetUrlInput
    ) {
      setNewsError(true);
      return;
    } else {
      const instructor = instructors?.find(
        (cat: Instructor) => cat.name === name
      );
      addInstructor(
        instructor?.name,
        instructorName,
        phone,
        instructor?.ref,
        assetUrlInput
      ).then(() => setSuccess(true));
    }
  };

  useEffect(() => {
    if (success) {
      if (newsError) setNewsError(false);
      const timeout1 = setTimeout(() => setSuccess(false), 3500);
      return () => {
        if (timeout1) clearTimeout(timeout1);
      };
    }
  }, [success, newsError]);

  return (
    <div data-error={newsError} className="inputs">
      <h4>Edytuj Instruktora </h4>
      <b>Podaj Imie Instruktora</b>
      <input placeholder="Podaj Imię" ref={nameRef} type="text" />
      <p>Podaj Telefon Instruktora najlepiej w formacie +48 000 000 000 </p>
      <input placeholder="+48 500 500 500" ref={telefonRef} type="text" />
      <p> School SKATE</p>
      <input
        className="check"
        placeholder="skate"
        onChange={(e) => handleSkateRefChange(e)}
        ref={skateRef}
        type="checkbox"
      />
      <p>School ROWER</p>
      <input
        className="check"
        placeholder="rower"
        onChange={(e) => handleRowerRefChange(e)}
        ref={rowerRef}
        type="checkbox"
      />
      <p>School ROLKI</p>
      <input
        className="check"
        placeholder="rolki"
        onChange={(e) => handleRolkiRefChange(e)}
        ref={rolkiRef}
        type="checkbox"
      />
      <p>School HULAJNOGA</p>
      <input
        className="check"
        placeholder="hulajnoga"
        onChange={(e) => handleScooterRefChange(e)}
        ref={scooterRef}
        type="checkbox"
      />
      <b style={{ display: 'block' }}>School Group</b>
      <input
        className="check"
        placeholder="group"
        defaultChecked={true}
        onChange={(e) => handleGroupRefChange(e)}
        ref={groupRef}
        type="checkbox"
      />
      <h5>
        Dodaj zdjęcie Rozkładu jazdy Grupowej lub Skate School w formacie .png
      </h5>
      <input
        placeholder="Wybierz Zdjęcie "
        onChange={(e) => handleImageAsUrl(e.target.files)}
        accept="image/*"
        type="file"
      />
      {newsError && <h4>Cos poszło nie tak.. spróbuj ponownie</h4>}
      {success && <h4>Instruktor został zedytowany pomyślnie</h4>}
      {!imageUploading && (
        <button
          onClick={() => {
            validateAndAddInstructor(
              getNameFromCheckBoxes(),
              nameRef.current?.value,
              telefonRef.current?.value,
              allInstructors,
              assetUrlInput
            );
          }}
        >
          Edytuj
        </button>
      )}
      {imageUploading && <LoadingSpinner />}
    </div>
  );
};

export default AdminInstructorsInput;

import { memo } from 'react';

import { ReactComponent as LoadingSpinnerIcon } from '../../assets/loadingSpinner.svg';
import './loadingspinner.scss';

const LoadingSpinner = () => {
  return <LoadingSpinnerIcon className="loadingspinner" />;
};

export default memo(LoadingSpinner);

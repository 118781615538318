import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './Contact.scss';
import { ReactComponent as GettoLogo } from '../../assets/get.to.park_logo.svg';

const Contact = () => {
  return (
    <div className="getto-contact-section">
      <Row>
        <Col>
          <h3>Kontakt</h3>
          <Col>
            <b>Mail:</b> <h5> skateparksb@gmail.com </h5>
          </Col>
          <Col>
            <b>Telefon:</b> <h5> +48 798 788 289 </h5>
          </Col>
          <b> Adres:</b>
          <p className="getto-contact-section-adres-sub">
            Rejtana 3 Bielsko-Biała 43-300
          </p>
        </Col>
        <Col className='getto-contact-section-logo-column'>
          <GettoLogo width={225} height={225} />
        </Col>
      </Row>
    </div>
  );
};

export default Contact;

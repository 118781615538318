import { FC } from 'react';
import Container from 'react-bootstrap/Container';

import './GettoSection.scss';

const GettoSection: FC<{
  children: React.ReactNode;
  isBackGroundBlack?: boolean;
}> = ({ children, isBackGroundBlack = false }) => {
  return (
    <Container className="getto-section" fluid>
      {children}
    </Container>
  );
};

export default GettoSection;

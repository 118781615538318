import { FC , createContext, useContext } from 'react'
import { auth } from '../getto-client/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Instructor, useInstructors } from '../getto-client/collections/instructors';
import { Schedule, useSchedules } from '../getto-client/collections/schedules';

export interface AdminContext {
  allSchedules: Schedule[],
  allInstructors: Instructor[]
};

const initialState: AdminContext = {
  allSchedules: [],
  allInstructors: []
};

const adminContext = createContext<AdminContext>(initialState);
const useAdminContext = () => useContext(adminContext);
const { Provider, Consumer: AdminStateConsumer } = adminContext;

const AdminProvider: FC = ({children}) => {
  const [authUser, _authLoading] = useAuthState(auth);

  const allSchedules = useSchedules(authUser);
  const allInstructors = useInstructors(authUser);


  const value = {
    allSchedules,
    allInstructors,
  };

  return(<Provider value={value}>{children}</Provider>);
}

export {
  adminContext,
  useAdminContext,
  AdminProvider,
  AdminStateConsumer
};

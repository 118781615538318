import React from 'react';

import { ReactComponent as GettoLogo } from '../../assets/get.to.park_logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';

import './PageNotFound.scss';

const PageNotFound = () => {
  return (
    <span className="page-not-found">
      <h5>Ta strona nie istnieje..</h5>
    </span>
  );
};

export default PageNotFound;

import { FC, useCallback, useState, useRef, SyntheticEvent } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Image, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAppStateContext } from '../../contexts/app-state-context';

import './Prices.scss';

const PricesSchoolRedirectionRow: FC = () => {
  const navigate = useNavigate();

  const extendedNavigate = useCallback((path?: string) => {
    if (!path) return;
    window.scrollTo(0, 0);
    navigate(path);
  }, []);
  return (
    <Row>
      <Col>
        <Button
          onClick={() => {
            extendedNavigate('/school/skate');
          }}
          className="getto-prices-section-button"
        >
          SKATE
        </Button>
      </Col>
      <Col>
        <Button
          onClick={() => {
            extendedNavigate('/school/rolki');
          }}
          className="getto-prices-section-button"
        >
          ROLKI
        </Button>
      </Col>
      <Col>
        <Button
          onClick={() => {
            extendedNavigate('/school/rower');
          }}
          className="getto-prices-section-button"
        >
          ROWER
        </Button>
      </Col>
      <Col>
        <Button
          onClick={() => {
            extendedNavigate('/school/scooter');
          }}
          className="getto-prices-section-button"
        >
          HULAJNOGA
        </Button>
      </Col>
    </Row>
  );
};

const Prices = () => {
  const { schedules, setPhotoInModal } = useAppStateContext();
  const mainScheduleImage = schedules?.find(
    (schedule) => schedule?.name && schedule.name === 'MAIN'
  );
  const mainScheduleImage2 = schedules?.find(
    (schedule) => schedule?.name && schedule.name === 'MAIN2'
  );
  const [imageLoading, setImageLoading] = useState(true);
  const imageRef = useRef<HTMLImageElement>(null);
  const handleImageLoaded = (
    event: SyntheticEvent<HTMLImageElement, Event>
  ) => {
    if (imageRef.current && imageRef.current.complete) setImageLoading(false);
  };

  return (
    <div className="getto-prices-section">
      <div className="dark-background" />
      <Row style={{ marginTop: 5 }}>
        <Col>
          <h3>Grafik i Cennik</h3>
        </Col>
      </Row>
      <Row
        xs={1}
        md={2}
        lg={2}
        style={{ marginTop: 5 }}
        className="getto-home-section-photos-row"
      >
        <Col>
          <div className="getto-home-section-photos-single">
            {mainScheduleImage?.assetUrl && (
              <Image
                onClick={() =>
                  setPhotoInModal({ name: mainScheduleImage.assetUrl })
                }
                ref={imageRef}
                style={imageLoading ? { opacity: '0.4' } : {}}
                onLoad={(event) => handleImageLoaded(event)}
                src={mainScheduleImage.assetUrl}
                alt="prices-Image"
              />
            )}
          </div>
        </Col>
        <Col>
          <div className="getto-home-section-photos-single">
            {mainScheduleImage2?.assetUrl && (
              <Image
                onClick={() =>
                  setPhotoInModal({ name: mainScheduleImage2.assetUrl })
                }
                src={mainScheduleImage2.assetUrl}
                alt="prices-Image-2"
              />
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <h3 className="dark-color-header">Sprawdź grafik Get To School:</h3>
      </Row>
      <PricesSchoolRedirectionRow />
    </div>
  );
};

export default Prices;

import { FC } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import RulesImage1 from '../../assets/regulamin.png';
import Rules2Image from '../../assets/rules.png';
import Image from 'react-bootstrap/Image';
import { useAppStateContext } from '../../contexts/app-state-context';

import './Rules.scss';

const Rules: FC = () => {
  const { setPhotoInModal } = useAppStateContext();
  return (
    <div className="getto-rules-section">
      <Row
        xs={1}
        md={2}
        lg={2}
        style={{ marginTop: 65 }}
        className="getto-home-section-photos-row"
      >
        <Col>
          <div className="getto-home-section-photos-single">
            <Image
              onClick={() => setPhotoInModal({ name: RulesImage1 })}
              src={RulesImage1}
              alt="skate-park-photo-2"
            />
          </div>
        </Col>
        <Col>
          <div className="getto-home-section-photos-single">
            <Image
              onClick={() => setPhotoInModal({ name: Rules2Image })}
              src={Rules2Image}
              alt="skate-park-photo-3"
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Rules;

import { useEffect, useState } from 'react';
import firebase from 'firebase';
import { db } from '../firebase';

export interface Schedule {
  id: string;
  ref: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
  name: string;
  assetUrl?: string;
}

export const useSchedules = (user?: firebase.User | null) => {
  const [instructors, setInstructors] = useState<Schedule[]>([]);

  useEffect(() => {
    if (!user) return;
    const unsubscribe = db
      .collection('Schedules')
      .onSnapshot((querySnapshot) => {
        var _schedules: Schedule[] = [];
        querySnapshot.forEach((doc) => {
          const _newOne = doc.data() as Schedule;
          _newOne.id = doc.ref.id;
          _newOne.ref = doc.ref;
          _schedules.push(_newOne);
        });
        setInstructors(_schedules);
      });
    return () => unsubscribe();
  }, [user]);

  return instructors;
};

export const addSchedule = async (
  name: string | undefined,
  scheduleRef: firebase.firestore.DocumentReference<firebase.firestore.DocumentData> | undefined,
  asset_url: string
) => {
  if (!scheduleRef || !asset_url || !name || !name?.length) return;
  await scheduleRef
    .update({
      name: name,
      assetUrl: asset_url,
    })
    .then(() => {})
    .catch((err) => {
      console.error('Error updating Schedule', err);
    });
};

import { useEffect, useState } from 'react';
import firebase from 'firebase';
import { db } from '../firebase';

export interface Instructor {
  id: string;
  ref: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
  name: string;
  phone: string;
  instructorName?: string;
  aboutText?: string;
  instructorText?: string;
  assetUrl?: string;
}

export const useInstructors = (user?: firebase.User | null) => {
  const [instructors, setInstructors] = useState<Instructor[]>([]);

  useEffect(() => {
    if (!user) return;
    const unsubscribe = db
      .collection('Instructors')
      .onSnapshot((querySnapshot) => {
        var _instructors: Instructor[] = [];
        querySnapshot.forEach((doc) => {
          const _newOne = doc.data() as Instructor;
          _newOne.id = doc.ref.id;
          _newOne.ref = doc.ref;
          _instructors.push(_newOne);
        });
        setInstructors(_instructors);
      });
    return () => unsubscribe();
  }, [user]);

  return instructors;
};

export const addInstructor = async (
  name: string | undefined,
  instructorName: string | undefined,
  phone: string | undefined,
  instructorRef:
    | firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
    | undefined,
  assetUrl: string | undefined
) => {
  if (
    !name ||
    !name?.length ||
    !phone ||
    !phone?.length ||
    !assetUrl ||
    !instructorName ||
    !instructorName.length
  )
    return;
  await instructorRef
    ?.update({
      name: name,
      phone: phone,
      instructorName: instructorName,
      assetUrl: assetUrl
    })
    .then(() => {})
    .catch((err) => {
      console.error('Error adding Product', err);
    });
};

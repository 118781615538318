import { Outlet } from 'react-router-dom';
import { useAppStateContext } from '../../contexts/app-state-context';
import GettoSplashScreen from '../../pages/getto-splash/GettoSplashScreen';
import Footer from '../footer/Footer';
import GettoNavbar from '../getto-navbar/GettoNavbar';
import GettoSection from '../getto-section/GettoSection';
import PhotoModal from '../photo-modal/PhotoModal';
import './GettoMainSection.scss';

const GettoMainSection = () => {
  const { photoInModal, schedules } = useAppStateContext();
  if (!schedules.length || !schedules?.length) return <GettoSplashScreen />;
  return (
    <section className="getto-main-section">
      <GettoNavbar />
      <section className="getto-sub-section">
        <GettoSection>
          <Outlet />
        </GettoSection>
      </section>
      {photoInModal?.name && <PhotoModal photoInModal={photoInModal} />}
      <Footer />
    </section>
  );
};

export default GettoMainSection;

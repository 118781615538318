import { memo } from 'react';
import { SocialIcon } from 'react-social-icons';
import './footer.scss';

const Footer = () => {
  return (
    <div className="footer_content">
      <div className="row">
        <div className="column">
          <h4>KONTAKT</h4>
          <ul className="list_unstyled">
            <li>Rejtana 3</li>
            <li>Bielsko Biała</li>
            <br />
            <li>skateparksb@gmail.com</li>
            <li>+48 798 788 289</li>
          </ul>
        </div>
        <div className="column">
          <ul className="list_unstyled">
            <li className="socials">
              <SocialIcon
                className="social1"
                bgColor={'white'}
                url="https://www.facebook.com/people/Get-to-Skatepark/100086755381908/"
              />
              <SocialIcon
                className="social2"
                bgColor={'white'}
                url="https://www.instagram.com/get.to.skatepark"
              />
            </li>
            <p>Copyrights @GetToPark 2022</p>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default memo(Footer);

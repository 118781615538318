import { FC } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useLocation } from 'react-router-dom';
import SchoolSection from '../../components/school-section/SchoolSection';

const GettoSchool: FC = () => {
  const location = useLocation();

  switch (location.pathname) {
    case '/school/skate':
      return <SchoolSection name={'SKATE'} />;
    case '/school/rower':
      return <SchoolSection name={'ROWER'} />;
    case '/school/rolki':
      return <SchoolSection name={'ROLKI'} />;
    case '/school/scooter':
      return <SchoolSection name={'HULAJNOGA'} />;
    default:
      return <SchoolSection name="SKATE" />;
  }
};

export default GettoSchool;

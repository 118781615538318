import { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import { db, signOut } from '../firebase';

export interface User {
  id: string;
  ref: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
  email: string;
}

export const useCurrentUser = (
  authUser?: firebase.User | null
): [User | null | undefined, boolean | undefined, boolean | undefined] => {
  const [user, setUser] = useState<User>();
  const [loading, setLoading] = useState(false);
  const [isAuthError, setIsAuthError] = useState(false);

  useEffect(() => {
    if (!authUser || !authUser.email) {
      setUser(undefined);
      setLoading(false);
      return;
    }
    setLoading(true);
    const _users: User[] = [];
    const unsubscribe = db
      .collection('Users')
      .where('email', '==', authUser.email)
      .onSnapshot(
        (userQS) => {
          userQS.docs.forEach((userDoc) => {
            if (userDoc.exists) {
              const _user = userDoc.data() as User;
              _user.ref = userDoc.ref;
              _users.push(_user);
            } else {
              console.error('No admin with such email..');
            }
          });
          if (_users && _users.length > 0) {
            setUser(_users[0]);
            setLoading(false);
            setIsAuthError(false);
          } else {
            setUser(undefined);
          }
        },
        (error) => {
          console.log('Error getting permissions for admin login.');
          signOut();
          setIsAuthError(true);
          setLoading(false);
          setUser(undefined);
        }
      );
    return () => unsubscribe();
  }, [authUser]);

  return [user, loading, isAuthError];
};

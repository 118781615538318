import React, {useEffect, useState } from "react";
import AdminSplash from './admin-splash/AdminSplash';
import AdminPage from '../pages/admin/AdminPage';
import { useAuthState } from "react-firebase-hooks/auth";
import { useCurrentUser } from "../getto-client/collections/users";
import { auth, signOut } from '../getto-client/firebase';
import firebase from "firebase/app"


import './admin-app.scss'


const AdminApp = () => {
   const [authUser, _authLoading] = useAuthState(auth);
   const [user, isLoading, isAdminError] = useCurrentUser(authUser);
   const [authError, setAuthError] = useState(false);

  let provider =  new firebase.auth.GoogleAuthProvider();
  provider.setCustomParameters({
    prompt: 'select_account'
  });
  const signIn = () => {
        firebase.auth()
        .signInWithPopup(provider)
        .then((result) => {
        }).catch((error) => {
            // Handle Errors here.
            setAuthError(true);
            console.log('Error signing in Admin Panel...')
        });
    }
    
  useEffect(() => {
    if(isAdminError){
      signOut();
    }
  },[isAdminError])

  return (
  <div>
        {!user && !_authLoading && <AdminSplash state={'sign-in'} signIn={signIn} />}
        {(_authLoading || isLoading) && !authError && <AdminSplash state={'loading'}/>}
        {user && !isAdminError && !isLoading && <AdminPage/>}
        
  </div>
  )
}

export default AdminApp;

import { FC, useCallback, useMemo, useState } from 'react';
import { ReactComponent as RoslinnyLogo } from '../../assets/get.to.park_logo.svg';
import { signOut } from '../../getto-client/firebase';
import { AdminProvider } from '../../contexts/admin-context';
import SchedulesPanel from '../../admin/schedules/SchedulesPanel';
import InstructorsPanel from '../../admin/instructors/InstructorsPanel';

import './admin-page.scss';

const AdminPage: FC = () => {
  const [whatViewOpened, setWhatViewOpened] = useState<
    'instructors-panel' | 'schedules-panel' | undefined
  >('schedules-panel');
  const displayAdminPanel = useCallback(() => {
    switch (whatViewOpened) {
      case 'instructors-panel':
        return <InstructorsPanel />;
      case 'schedules-panel':
        return <SchedulesPanel />;
      default:
        return <InstructorsPanel />;
    }
  }, [whatViewOpened]);

  return (
    <AdminProvider>
      <div className="AdminApp">
        <RoslinnyLogo width={200} />
        <h1>PANEL ADMIN</h1>
        <button className="signout-button" onClick={() => signOut()}>
          Wyloguj się
        </button>
        <div className="admin-nav">
          <button
            data-selected={whatViewOpened !== 'schedules-panel' ? true : false}
            className="panel-button"
            onClick={() => {
              setWhatViewOpened('instructors-panel');
            }}
          >
            Instruktorzy
          </button>
          <button
            data-selected={whatViewOpened === 'schedules-panel' ? true : false}
            className="panel-button"
            onClick={() => {
              setWhatViewOpened('schedules-panel');
            }}
          >
            Rozkłady Jazdy
          </button>
          <h4 className="AdminApp-work-correctly">
            Aby strona działała poprawie obrazki nie powinny przekraczać 15MB
            !!!
          </h4>
        </div>
        {displayAdminPanel()}
      </div>
    </AdminProvider>
  );
};

export default AdminPage;

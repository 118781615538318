import { forwardRef } from 'react';
import { useInView } from 'react-intersection-observer';
import HomeSectionCarousel from '../../components/home-section-carousel/HomeSectionCarousel';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import SkateParkImage1 from '../../assets/skatepark1.png';
import SkateParkImage2 from '../../assets/skatepark2.png';
import SkateParkImage3 from '../../assets/skatepark3.png';
import ShopImage1 from '../../assets/sklep1.png';
import ShopImage2 from '../../assets/sklep2.png';
import { ReactComponent as Map } from '../../assets/map.svg';
import { useAppStateContext } from '../../contexts/app-state-context';
import './Home.scss';

const HomeGettoAdress = forwardRef<any, { inView: boolean }>(
  ({ inView }, ref) => {
    return (
      <Row style={{ marginTop: 65 }} className="getto-home-section-address-row">
        <Col ref={ref} data-visible={inView}>
          <h3 className="dark-color-header">Adres i Dojazd</h3>
          <h5 className="dark-color-header">ulica Rejtana 3 </h5>
          <h5 className="dark-color-header">Bielsko-Biała, Poland</h5>
        </Col>
        <Col className="getto-home-section-map-column">
          <Map />
        </Col>
      </Row>
    );
  }
);

const HomeSkateparkPhotos = () => {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0
  });
  const { setPhotoInModal } = useAppStateContext();
  return (
    <Row
      xs={1}
      md={2}
      lg={3}
      style={{ marginTop: 65 }}
      className="getto-home-section-photos-row"
    >
      <Col ref={ref} data-visible={inView}>
        <div className="getto-home-section-photos-single">
          <Image
            onClick={() => setPhotoInModal({ name: SkateParkImage1 })}
            src={SkateParkImage1}
            alt="skate-park-photo-1"
          />
        </div>
      </Col>
      <Col ref={ref} data-visible={inView}>
        <div className="getto-home-section-photos-single">
          <Image
            onClick={() => setPhotoInModal({ name: SkateParkImage2 })}
            src={SkateParkImage2}
            alt="skate-park-photo-2"
          />
        </div>
      </Col>
      <Col ref={ref} data-visible={inView}>
        <div className="getto-home-section-photos-single">
          <Image
            onClick={() => setPhotoInModal({ name: SkateParkImage3 })}
            src={SkateParkImage3}
            alt="skate-park-photo-3"
          />
        </div>
      </Col>
    </Row>
  );
};

const HomeKorPow = () => {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0
  });
  const redirectToKorpow = () => {
    const url = 'https://korpow.com/shop/';
    window.open(url, '_blank');
  };
  const { setPhotoInModal } = useAppStateContext();
  return (
    <>
      <Col>
        <h3 className="dark-color-header">Sklep KOR POW</h3>
        <h5 className="dark-color-header">
          sklep z sprzętem deskorolkowym dostępny stacjonarnie w Get To
          Skatepark Bielsko-Biała oraz online
        </h5>
      </Col>
      <Row
        xs={1}
        md={2}
        lg={2}
        style={{ marginTop: 65 }}
        className="getto-home-section-photos-row"
      >
        <Col ref={ref} data-visible={inView}>
          <div className="getto-home-section-photos-single">
            <Image
              onClick={() => setPhotoInModal({ name: ShopImage1 })}
              src={ShopImage1}
              alt="skate-park-photo-1"
            />
          </div>
        </Col>
        <Col ref={ref} data-visible={inView}>
          <div className="getto-home-section-photos-single">
            <Image
              onClick={() => setPhotoInModal({ name: ShopImage2 })}
              src={ShopImage2}
              alt="skate-park-photo-2"
            />
          </div>
        </Col>
      </Row>
      <Col>
        <div className="getto-home-section-korpow">
          <Button
            onClick={redirectToKorpow}
            className="getto-home-section-korpow-button"
          >
            Odwiedź KOR POW online
          </Button>
        </div>
      </Col>
    </>
  );
};

const Home = () => {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0
  });
  return (
    <div className="getto-home-section">
      <div className="dark-background" />
      <Row style={{ marginTop: 5 }}>
        <Col>
          <h3 className="getto-home-section-header">
            Szkoła Sportów Ekstremalnych GET.TO SCHOOL w Bielsku-Białej
          </h3>
        </Col>
      </Row>
      <Row>
        <HomeSectionCarousel />
      </Row>
      <HomeGettoAdress inView={inView} ref={ref} />
      <Row>
        <h3 className="photos-header dark-color-header">Zdjęcia Skateparku</h3>
        <h5 className="dark-color-header">
          zdjęcia krytego skateparku w Bielsku Białej
        </h5>
        <HomeSkateparkPhotos />
      </Row>
      <Row>
        <HomeKorPow />
      </Row>
      <div className="dark-background" />
    </div>
  );
};

export default Home;

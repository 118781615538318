import { FC, memo, useState } from 'react';
import Image from 'react-bootstrap/Image';
import { useAppStateContext } from '../../contexts/app-state-context';
import CloseButton from '../close-button/CloseButton';
import LoadingSpinner from '../loading-spinner/LoadingSpinner';

import './PhotoModal.scss';

export interface Photo {
  name: string | undefined;
}

const PhotoModal: FC<{
  photoInModal: Photo;
}> = ({ photoInModal }) => {
  const { setPhotoInModal } = useAppStateContext();
  return (
    <div className="photo-modal">
      <div className="photo-modal-backdrop" />
      <div className="photo-modal-container">
        <CloseButton
          closingFunction={() => setPhotoInModal({ name: undefined })}
        />
        <div className="modal-content">
          <div className="image-container">
            {photoInModal?.name ? (
              <Image src={photoInModal.name} alt="product" />
            ) : (
              <LoadingSpinner />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(PhotoModal);

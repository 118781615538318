import { FC } from 'react';
import LoadingSpinner from '../../components/loading-spinner/LoadingSpinner';
import './admin-splash.scss';


const AdminSplash: FC<{
  state: 'loading' | 'error' | 'sign-in';
  signIn?: () => void;
}> = ({ state, signIn }) => {
  return (
    <div className="splash">
      {state === 'sign-in' && signIn && (
        <div>
          <h2> Zaloguj Się do Panelu Administratora Get To Park: </h2>
          <button onClick={() => signIn()}> Zaloguj się</button>
        </div>
      )}
      {state === 'error' && signIn && (
        <div>
          <h2> ERROR niepoprawne dane logowania... </h2>
          <button onClick={() => signIn()}> Zaloguj się</button>
        </div>
      )}
      {state === 'loading' && (
        <div>
          <LoadingSpinner />
        </div>
      )}
    </div>
  );
};

export default AdminSplash;

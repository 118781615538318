// Import the functions you need from the SDKs you need
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyDMMr73g7-8h8YMQ1IyyR6NB-zjtNRsa6I',
  authDomain: 'getto-db.firebaseapp.com',
  projectId: 'getto-db',
  storageBucket: 'getto-db.appspot.com',
  messagingSenderId: '51759919790',
  appId: '1:51759919790:web:bc299cc6303114e048b33f'
};

// // Initialize Firebase
// const app = firebase.initializeApp(firebaseConfig);

// Initialize Firebase
const configApp = firebase.initializeApp(firebaseConfig);
export const db = configApp.firestore();
export const storage = configApp.storage();
export const auth = firebase.auth();

export const signOut = () => {
  firebase
    .auth()
    .signOut()
    .then(() => {
      // Sign-out successful.
    })
    .catch((error) => {
      // An error happened.
      console.log('error loggin out');
    });
};

export const handleImageAsFile = (file: File) => {
  var uploadTask = storage
    .ref()
    .child(`${file.name}-${Date.now().toString()}`)
    .put(file);
  uploadTask.on(
    firebase.storage.TaskEvent.STATE_CHANGED,
    (snapshot) => {
      switch (snapshot.state) {
        case firebase.storage.TaskState.PAUSED:
          break;
        case firebase.storage.TaskState.RUNNING:
          break;
      }
    },
    (error) => {
      console.error('error uploading ', error);
    }
  );
  return uploadTask.then(() => uploadTask.snapshot.ref.getDownloadURL());
};

import {
  FC,
  useState,
  useEffect,
  useContext,
  createContext,
  useMemo
} from 'react';
import { Photo } from '../components/photo-modal/PhotoModal';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../getto-client/firebase';
import firebase from 'firebase/app';
import { Schedule, useSchedules } from '../getto-client/collections/schedules';

export interface AppStateContext {
  photoInModal: Photo;
  setPhotoInModal: (photo: Photo) => void;
  authUser: firebase.User | null | undefined;
  isAuthError: boolean;
  setIsAuthError: (value: boolean) => void;
  schedules: Schedule[];
}

const initialState: AppStateContext = {
  photoInModal: { name: undefined },
  setPhotoInModal: () => {},
  authUser: undefined,
  isAuthError: false,
  setIsAuthError: () => {},
  schedules: []
};

interface Props {
  children: React.ReactNode;
}

const appStateContext = createContext<AppStateContext>(initialState);
const useAppStateContext = () => useContext(appStateContext);
const { Provider, Consumer: AppStateConsumer } = appStateContext;

const AppStateProvider: FC<Props> = ({ children }) => {
  const [photoInModal, setPhotoInModal] = useState<Photo>({ name: undefined });
  // auth variables
  const [authUser, _authLoading] = useAuthState(auth);
  const [isAuthError, setIsAuthError] = useState(false);
  // content variables
  const schedules = useSchedules(authUser);

  useEffect(() => {
    if (!authUser) {
      firebase
        .auth()
        .signInAnonymously()
        .then((response) => {})
        .catch((error) => {
          console.error(error);
        });
    } else if (authUser) {
      setIsAuthError(true);
    } else if (authUser) {
      setIsAuthError(false);
    }
  }, [authUser]);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setIsAuthError(false);
      } else {
      }
    });
  }, [authUser]);

  const value = useMemo(
    () => ({
      photoInModal,
      setPhotoInModal,
      authUser,
      isAuthError,
      setIsAuthError,
      schedules
    }),
    [
      photoInModal,
      setPhotoInModal,
      authUser,
      isAuthError,
      setIsAuthError,
      schedules
    ]
  );

  return <Provider value={value}>{children}</Provider>;
};

export {
  appStateContext,
  AppStateConsumer,
  AppStateProvider,
  useAppStateContext
};

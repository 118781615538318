import { useState, useRef, FC, SyntheticEvent } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Image from 'react-bootstrap/Image';
import { useAppStateContext } from '../../contexts/app-state-context';
import Home1 from '../../assets/majka1.png';
import Home2 from '../../assets/majka2.png';

import './HomeSectionCarousel.scss';

const HomeSectionCarousel: FC = () => {
  const [index, setIndex] = useState(0);
  const [imageLoading, setImageLoading] = useState(true);
  const imageRef = useRef<HTMLImageElement>(null);
  const { schedules } = useAppStateContext();

  const welcomeImage = schedules?.find(
    (asset) => asset?.name && asset.name === 'HOME'
  );

  const handleImageLoaded = (
    event: SyntheticEvent<HTMLImageElement, Event>
  ) => {
    if (imageRef.current && imageRef.current.complete) setImageLoading(false);
  };

  const handleSelect = (
    selectedIndex: number,
    e: Record<string, unknown> | null
  ) => {
    setIndex(selectedIndex);
  };
  return (
    <Carousel
      className="home-carousel"
      variant={index === 2 ? 'dark' : ''}
      activeIndex={index}
      onSelect={handleSelect}
      interval={6000}
    >
      <Carousel.Item>
        {welcomeImage?.assetUrl && (
          <Image
            ref={imageRef}
            style={imageLoading ? { opacity: '0.4' } : {}}
            onLoad={(event) => handleImageLoaded(event)}
            src={welcomeImage.assetUrl}
            alt="firstSlide"
          />
        )}
        <Carousel.Caption></Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <Image src={Home1} alt="secondSlide" />
        <Carousel.Caption></Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <Image src={Home2} alt="thirdSlide" />
        <Carousel.Caption></Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};

export default HomeSectionCarousel;

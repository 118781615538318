import { FC, useCallback, useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import { useAppStateContext } from '../../contexts/app-state-context';
import {
  Instructor,
  useInstructors
} from '../../getto-client/collections/instructors';
import LoadingSpinner from '../loading-spinner/LoadingSpinner';

import './SchoolSection.scss';

const schoolNames = ['SKATE', 'ROLKI', 'HULAJNOGA', 'ROWER'];

const SchoolNamedSection: FC<{
  name: string;
  instructor?: Instructor;
}> = ({ name, instructor }) => {
  const { setPhotoInModal } = useAppStateContext();

  const getSchoolName = useCallback(() => {
    if (name === schoolNames[0]) return 'deskorolki';
    if (name === schoolNames[1]) return 'rolki';
    if (name === schoolNames[2]) return 'hulajnogi';
    if (name === schoolNames[3]) return 'rower BMX';
  }, []);
  if (
    !instructor ||
    !instructor?.instructorName ||
    !instructor?.instructorName ||
    !instructor?.phone ||
    !instructor?.assetUrl
  )
    return (
      <Row className="loading-row">
        <LoadingSpinner />
      </Row>
    );
  return (
    <Row xs={1} md={1} lg={2} className="group-row">
      <Col md="auto" className="group-contact-column">
        <h2>{`Instruktor: ${instructor?.instructorName}`}</h2>
        <Col>
          <b>Telefon:</b> <h5> {instructor?.phone} </h5>
        </Col>
        <Col>
          <b> Szkoła {getSchoolName()}:</b>
          {instructor?.aboutText && <h6>{instructor.aboutText}</h6>}
          {instructor?.instructorText && (
            <Col>
              <b>
                {name === schoolNames[2]
                  ? 'O instruktorach'
                  : 'O instruktorze:'}
              </b>
              <h6>{instructor.instructorText}</h6>
            </Col>
          )}
        </Col>
      </Col>
      <Col className="group-image-column">
        <Image
          onClick={() => setPhotoInModal({ name: instructor?.assetUrl })}
          src={instructor?.assetUrl}
          alt="skate-park-photo-1"
        />
      </Col>
    </Row>
  );
};

const SchoolGroupSection: FC<{
  instructor?: Instructor;
}> = ({ instructor }) => {
  const { setPhotoInModal } = useAppStateContext();
  if (!instructor || !instructor?.phone || !instructor?.assetUrl) {
    return (
      <Row className="loading-row">
        <LoadingSpinner />
      </Row>
    );
  }
  return (
    <Row xs={1} md={1} lg={2} className="group-row">
      <Col md="auto" className="group-contact-column">
        <h2>Zapisy:</h2>
        <Col>
          <b>Telefon:</b> <h5> {instructor.phone} </h5>
        </Col>
        <Col>
          <b>Mail:</b> <h5> skateparksb@gmail.com </h5>
        </Col>
      </Col>
      <Col className="group-image-column">
        <Image
          onClick={() => setPhotoInModal({ name: instructor.assetUrl })}
          src={instructor.assetUrl}
          alt="skate-park-photo-1"
        />
      </Col>
    </Row>
  );
};

const SchoolSection: FC<{ name: string }> = ({ name }) => {
  const [showGroupClasses, setShowGroupClasses] = useState(false);
  const { authUser: user } = useAppStateContext();
  const instructors = useInstructors(user);
  const instructor = instructors?.find(
    (instructor) => instructor?.name && instructor.name === name
  );
  const groupInstructor = instructors?.find(
    (instructor) => instructor?.name && instructor.name === 'GROUP'
  );

  useEffect(() => {
    if (showGroupClasses) setShowGroupClasses(false);
  }, [name]);

  return (
    <div className="school-section">
      <div className="dark-background" />
      <Row className="school-section-row">
        <Col>
          <h3 className="dark-color-header">
            {showGroupClasses ? `Zajęcia Grupowe` : `${name} school`}
          </h3>
        </Col>
      </Row>
      <Row className="school-section">
        <Col>
          <Button
            onClick={() => setShowGroupClasses((prev) => !prev)}
            className="school-section-group-button"
          >
            {!showGroupClasses
              ? `Zajęcia Grupowe`
              : `Wróć do zajęć indywidualnych`}
          </Button>
        </Col>
      </Row>
      {!showGroupClasses ? (
        <SchoolNamedSection name={name} instructor={instructor} />
      ) : (
        <SchoolGroupSection instructor={groupInstructor} />
      )}
    </div>
  );
};

export default SchoolSection;

import { FC } from 'react';
import { ReactComponent as GettoLogo } from '../../assets/get.to.park_logo.svg';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import Container from 'react-bootstrap/Container';
import NavDropdown from 'react-bootstrap/NavDropdown';
import GettoNavbarLinkContainer from './gett-navbar-link-container/GettoNavbarLinkContainer';

import './GettoNavbar.scss';

const GettoNavbar: FC = () => {
  return (
    <Navbar collapseOnSelect className="nav-bar" expand="lg">
      <Container className="nav-bar-container">
        <Navbar.Brand className="nav-bar-container-logo" href="/">
          <GettoLogo width={125} height={125} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav>
            <NavDropdown
              className="getto-nav-link"
              title="GRAFIK I CENNIK "
              id="basic-nav-dropdown"
            >
              <GettoNavbarLinkContainer
                path="/prices"
                title="GRAFIK I CENNIK"
                isInsideDropdown={true}
              />
              <NavDropdown.Divider />
              <GettoNavbarLinkContainer
                path="/rules"
                title="You know The rules buddy - REGULAMIN"
                isInsideDropdown={true}
              />
            </NavDropdown>
            <GettoNavbarLinkContainer path="/news" title="GETTO NEWSY" />
            <NavDropdown
              className="getto-nav-link"
              title="SZKOŁA JAZDY"
              id="basic-nav-dropdown"
            >
              <GettoNavbarLinkContainer
                path="/school/skate"
                title="SKATE"
                isInsideDropdown={true}
              />
              <GettoNavbarLinkContainer
                path="/school/rolki"
                title="ROLKI"
                isInsideDropdown={true}
              />
              <GettoNavbarLinkContainer
                path="/school/rower"
                title="ROWER"
                isInsideDropdown={true}
              />
              <GettoNavbarLinkContainer
                path="/school/scooter"
                title="HULAJNOGA"
                isInsideDropdown={true}
              />
            </NavDropdown>

            <Nav.Link
              className="getto-nav-link"
              href="https://korpow.com/shop/"
              target="_blank"
            >
              SKLEP
            </Nav.Link>
            <GettoNavbarLinkContainer path="/contact" title="KONTAKT" />
            <Nav.Link className="getto-nav-link" href="/"></Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default GettoNavbar;

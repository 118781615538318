import 'bootstrap/dist/css/bootstrap.min.css';
import { AppStateProvider } from './contexts/app-state-context';
import Router from './navigation/Router';

import './GettoApp.scss';

const GettoApp = () => {
  return (
    <AppStateProvider>
      <Router />
    </AppStateProvider>
  );
};

export default GettoApp;

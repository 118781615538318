import { FC } from 'react';
import AdminInstructorsInput from '../admin-inputs/InstructorsInput';


const InstructorsPanel: FC = () => {
  return (
    <div className="instructor-panel">
      <div>
        <AdminInstructorsInput />
        <div className="data-table" style={{ maxWidth: '100%' }}></div>
      </div>
    </div>
  );
};

export default InstructorsPanel;

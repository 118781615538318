import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './News.scss';
import { ReactComponent as GettoLogo } from '../../assets/get.to.park_logo.svg';

const News = () => {
  return (
    <span className="getto-news-section">
      <Row style={{ marginTop: 155 }}>
        <Col>
          <h3>Coming soon...</h3>
        </Col>
      </Row>
    </span>
  );
};

export default News;

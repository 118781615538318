import { FC, memo } from 'react';
import Nav from 'react-bootstrap/Nav';
import { LinkContainer } from 'react-router-bootstrap';
import NavDropdown from 'react-bootstrap/NavDropdown';

const GettoNavbarLinkContainer: FC<{
  title: string;
  path?: string;
  isInsideDropdown?: boolean;
}> = ({ title, path = '/', isInsideDropdown = false }) => {
  if (!isInsideDropdown) {
    return (
      <LinkContainer to={path}>
        <Nav.Link className="getto-nav-link">{title}</Nav.Link>
      </LinkContainer>
    );
  }

  return (
    <LinkContainer className="getto-nav-link" to={path}>
      <NavDropdown.Item className="getto-nav-dropdown-item">
        {title}
      </NavDropdown.Item>
    </LinkContainer>
  );
};

export default memo(GettoNavbarLinkContainer);
